import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Анхдагч зах зээлийн арилжааны мэдээллийг хэрхэн харах вэ?",
  "description": null,
  "author": "OTC help",
  "category": "market",
  "date": "2022-04-08T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 1
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Анхдагч зах зээлийн арилжааны мэдээллийг хэрхэн харах вэ?`}</h2>
    <ol>
      <li parentName="ol">{`Платформын үндсэн цэснээс `}<strong parentName="li">{`[Market]`}</strong>{` хэсгийг сонгон `}<strong parentName="li">{`[Primary]`}</strong>{` дэд цэсийг дарж ББЗЗ дэх анхдагч арилжааны нээлттэй болон хаагдсан үнэт цаасны арилжааны мэдээллийг харах боломжтой`}</li>
      <li parentName="ol">{`Тус хэсгээс сонгосон үнэт цаасны нэр дээр дарж тухайн үнэт цаасны дэлгэрэнгүй мэдээллийг авах боломжтой:`}
        <ul parentName="li">
          <li parentName="ul">{`Үнэт цаасны үнэ, тоо ширхэг, захиалга эхлэх болон дуусах хугацаа,`}</li>
          <li parentName="ul">{`Нийт татан төвлөрүүлэх дүнгийн биелэлт, явцын мэдээлэл`}</li>
          <li parentName="ul">{`Үнэт цаас гаргагчийн тайлан, мэдээ, үнэт цаасны танилцуулгыг харах, татах боломжтой`}</li>
          <li parentName="ul">{`Хэрэглэгчийн тухайн үнэт цаастай холбогдох захиалгын мэдээлэл, төлөвийг харах, мэдээллийг .csv өргөтгөлөөр татах боломжтой`}</li>
        </ul>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      